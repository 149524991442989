/* fix check */
.check_group {
  margin-top: 15px;
  .check_block {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #c5bdb3;
    float: left;
    margin-right: 10px;
    margin-top: 6px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    &.active {
      background-image: url(../images/ok_img.png); } } }
.close:hover {
  i.icon.icon-s-close {
    background-image: url(sprites/sprite.png);
    background-position: -212px -37px;
    width: 25px;
    height: 25px; } }
.owl-prev:hover {
  .icon-s-link_left {
    background-image: url(sprites/sprite.png);
    background-position: -177px -73px;
    width: 35px;
    height: 73px; } }
.owl-next:hover {
  .icon-s-link_right {
    background-image: url(sprites/sprite.png);
    background-position: -177px -73px;
    width: 35px;
    height: 73px;
    transform: scale(-1,1); } }
.slide {
  border: 2px solid #eee;
  box-shadow: 5px 10px 30px -11px #eee;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  float: left; }
hr.yellow {
  border-top: 0; }
/* fix styles */
.icon {
  display: block; }
* {
  outline: none !important; }
body {
  padding: 0 !important;
  margin: 0; }
.hiddenInput {
  display: none !important; }
/* fix file form */
.icon-s-file {
  cursor: pointer; }
.link_file {
  cursor: pointer; }
.file_group input {
  display: none !important; }
/* fix slider */
.owl-nav {
  height: 1px;
  position: absolute;
  width: 100%;
  top: 40%; }
.owl-prev {
  float: left;
  margin-left: -90px; }
.owl-next {
  float: right;
  margin-right: -90px; }
.icon-s-link_right {
  background-image: url(sprites/sprite.png);
  background-position: -177px 0px;
  width: 35px;
  height: 73px;
  transform: scale(-1,1); }
i.icon.icon-s-romb_gr,
i.icon.icon-s-romb_ye,
i.icon.icon-s-romb_bl {
  margin-left: -45px; }
ul.s_list3 li,
ul.s_list2 li,
ul.s_list li {
  padding-left: 45px; }
/* main style */
.btn_style a, a.btn_style, button.btn_style {
  text-transform: uppercase;
  font-family: Century Gothic;
  font-size: 16px;
  color: #2d281b;
  font-weight: bold;
  text-align: center;
  cursor: pointer; }
.c_title {
  font-family: Century Gothic;
  font-size: 30px;
  text-align: left;
  color: #2d281b;
  font-weight: bold; }
hr.yellow {
  width: 75px;
  float: left;
  border-bottom: 5px solid #ffa900; }
a.btn_style, button.btn_style {
  display: block;
  float: right;
  position: relative;
  background-color: #ffa900;
  width: 205px;
  text-align: center;
  padding-top: 5px;
  transition: .0s;
  z-index: 9;
  &:before {
    content: "";
    display: block;
    width: 105px;
    height: 10px;
    position: absolute;
    overflow: hidden;
    bottom: -5px;
    -webkit-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
    background-color: #ffa900;
    z-index: -1; }
  &:after {
    content: "";
    display: block;
    width: 105px;
    height: 10px;
    position: absolute;
    overflow: hidden;
    bottom: -5px;
    right: 0;
    -webkit-transform: rotate(-4deg);
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
    background-color: #ffa900;
    z-index: -1; }
  &:hover {
    text-decoration: none;
    background-color: #ffc148;
    &:after {
      background-color: #ffc148; }
    &:before {
      background-color: #ffc148; } } }
body {
  font-family: Roboto Regular;
  font-size: 15px;
  header {
    background-image: url(../images/bg1.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    background-size: cover;
    .container {
      padding-right: 8px; }
    .h_logo {
      padding-top: 11px;
      padding-left: 10px; }
    .h_text {
      padding-left: 15px;
      padding-right: 0;
      padding-top: 30px;
      p {
        color: #5d554e;
        font-size: 16px;
        text-align: center; } }
    .h_callback {
      padding-right: 0;
      float: right;
      margin-left: -10px;
      a.back_call {
        display: block;
        cursor: pointer;
        float: right;
        position: relative;
        background-color: #ffa900;
        width: 205px;
        text-align: center;
        padding-top: 5px;

        z-index: 999;
        &:before {
          content: "";
          display: block;
          width: 105px;
          height: 10px;
          position: absolute;
          overflow: hidden;
          bottom: -5px;
          -webkit-transform: rotate(20deg);
          -ms-transform: rotate(20deg);
          transform: rotate(5deg);
          background-color: #ffa900;
          z-index: -1; }
        &:after {
          content: "";
          display: block;
          width: 105px;
          height: 10px;
          position: absolute;
          overflow: hidden;
          bottom: -5px;
          right: 0;
          -webkit-transform: rotate(-5deg);
          -ms-transform: rotate(-5deg);
          transform: rotate(-5deg);
          background-color: #ffa900;
          z-index: -1; }
        &:hover {
          text-decoration: none;
          background-color: #ffc148;
          &:after {
            background-color: #ffc148; }
          &:before {
            background-color: #ffc148; } } } }
    .h_block {
      padding: 0; }
    .h_number {
      float: right;
      padding-right: 0px;
      padding-top: 13px;
      a {
        color: #000;
        font-family: Century Gothic;
        font-size: 26px;
        font-weight: bold;
        text-decoration: none; } }
    .title {
      font-family: Century Gothic;
      font-size: 50px;
      text-align: left;
      color: #2d281b;
      font-weight: bold;
      margin-top: 105px;
      padding-left: 18px;
      line-height: 50px; }
    .subtitle {
      font-family: Century Gothic;
      font-size: 28px;
      text-align: left;
      color: #2d281b;
      padding-left: 18px; }
    .h_list {
      padding-left: 18px;
      margin-top: 45px;
      p {
        color: #5d554e;
        font-family: Roboto Regular;
        font-size: 16px;
        text-align: left;
        padding-top: 5px;
        i.icon {
          float: left;
          margin-right: 10px;
          clear: left; } }
      a.btn_style {
        float: left;
        width: 303px;
        padding-top: 10px;
        margin-left: 5px;
        margin-top: 40px;
        margin-bottom: 305px;
        &:before {
          width: 154px;
          height: 25px;
          bottom: -15px;
          z-index: -1;
          border-radius: 0px 0px 0px 2px; }
        &:after {
          width: 154px;
          height: 25px;
          bottom: -15px;
          z-index: -1;
          border-radius: 0px 0px 0px 2px; } } } } }
#content1 {
  position: relative;
  .cnt1_img {
    position: absolute;
    right: 0;
    top: -110px; }
  .cnt1_img2 {
    position: absolute;
    top: 220px; }
  .c_title {
    margin-top: 115px;
    padding-left: 10px;
    p {
      line-height: 35px; }
    hr.yellow {
      margin-top: 13px;
      margin-left: -5px; } }
  .content {
    margin-top: 35px;
    padding-left: 13px;
    padding-right: 0;
    p {
      color: #5d554e;
      font-family: Roboto Regular;
      font-size: 16px;
      margin-bottom: 5px; }
    ul.cnt1_list {
      list-style-image: url(../images/list_link.png);
      padding-left: 20px;
      li {
        color: #5d554e;
        font-family: Roboto Regular;
        font-size: 16px;
        margin-bottom: 0px; }
      li:first-child {
        margin-bottom: 10px; } }
    p.afterlist {
      margin-top: -5px; } }
  .cnt1_blocks {
    border-top: 1px solid #f0e7dc;
    margin-top: 50px /* fix */;
    padding-top: 58px;
    .cnt1_block {
      min-height: 189px; }
    p.b_title {
      color: #2d281b;
      text-transform: uppercase;
      font-family: Roboto Regular;
      font-weight: bold;
      font-size: 16px;
      text-align: left; }
    p.b_descr {
      font-family: 16px;
      text-align: left;
      font-family: Roboto Regular;
      line-height: 18.5px;
      color: #5d554e; }
    .block1 {
      i.icon {
        margin-left: 5px;
        margin-right: 0px; }
      .col-md-9 {
        padding-left: 5px;
        padding-right: 0; } }
    .block2 {
      i.icon {
        margin-left: 20px; } }
    .block3 {
      i.icon {
        margin-left: 30px; }
      .col-md-9 {
        padding-right: 0;
        padding-left: 28px; } }
    .block4 {
      i.icon {
        margin-left: 10px; }
      .col-md-9 {
        padding-left: 5px; } }
    .block5 {
      i.icon {
        margin-left: 20px; } }
    .block6 {
      i.icon {
        margin-left: 25px;
        margin-top: -2px; }
      .col-md-9 {
        padding-left: 28px; } } }
  .border_top {
    border-top: 1px solid #f0e7dc;
    padding-top: 50px; } }

#content2 {
  background-color: #faf6e7;
  margin-top: 18px;
  padding-bottom: 85px;
  .c_title {
    margin-top: 65px;
    line-height: 38px;
    padding-left: 10px; }
  hr.yellow {
    margin-top: 10px;
    margin-left: 5px; }
  #answers {
    padding: 0; }
  .slide {
    .s_title {
      text-transform: uppercase;
      font-size: 16px;
      font-family: Roboto Regular;
      text-align: left;
      color: #2d281b;
      font-weight: bold; }
    ul.s_list {
      list-style-type: none;
      li {
        font-size: 16px;
        color: #5d554e;
        text-align: left;
        float: left;
        clear: left;
        i.icon {
          float: left;
          font-family: Century Gothic;
          font-style: normal;
          color: #2d281b;
          padding-top: 5px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-left: 3px;
          margin-right: 10px;
          margin-top: 5px;
          margin-bottom: 20px; } } }
    ul.s_list2 {
      list-style-type: none;
      li {
        font-size: 16px;
        color: #5d554e;
        text-align: left;
        float: left;
        clear: left;
        i.icon {
          float: left;
          font-family: Century Gothic;
          font-style: normal;
          color: #2d281b;
          padding-top: 5px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-left: 3px;
          margin-right: 10px;
          margin-top: 5px;
          margin-bottom: 20px; } } }
    ul.s_list3 {
      list-style-type: none;
      li {
        font-size: 16px;
        color: #5d554e;
        text-align: left;
        float: left;
        clear: left;
        i.icon {
          float: left;
          font-family: Century Gothic;
          font-style: normal;
          color: #fff;
          padding-top: 5px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          padding-left: 3px;
          margin-right: 10px;
          margin-top: 5px;
          margin-bottom: 20px; } } } }
  .block_top,
  .block_bottom {
    float: left;
    width: 100%; }
  .s_title {
    margin-top: 37px; }
  .s_block_left {
    background-color: #fff;
    padding-bottom: 70px;
    .s_title {
      padding-left: 65px; }
    ul.s_list {
      padding-left: 20px;
      padding-top: 8px;
      i.icon.icon-s-romb_ye {
        margin-top: -5px;
        margin-bottom: 25px; }
      li {
        margin-bottom: 8px; } } }
  .s_block_right {
    padding-left: 0;
    padding-right: 0;
    .block_top {
      background-color: #fff;
      box-shadow: 100px 0px 40px -90px #eee inset;
      padding-bottom: 20px;
      padding-right: 10px; }
    .s_title {
      padding-left: 90px; }
    ul.s_list2 {
      padding-left: 45px;
      padding-right: 20px;
      padding-top: 8px;
      i.icon.icon-s-romb_gr {
        margin-top: -5px;
        margin-bottom: 20px; }
      li {
        margin-bottom: 8px; }
      li:first-child {
        margin-bottom: -20px; } }
    .block_bottom {
      .s_title {
        margin-top: 35px;
        padding-left: 90px; }
      ul.s_list3 {
        padding-left: 45px;
        padding-right: 20px;
        li {
          margin-bottom: 5px;
          padding-top: 5px;
          i.icon {
            margin-top: -5px;
            letter-spacing: 3px; } } } } } }

#content3 {
  position: relative;
  background-image: url(../images/bg2.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  background-size: cover;
  padding-bottom: 332px;
  .cnt3_img {
    position: absolute;
    top: -145px;
    left: 0; }
  .cnt3_title {
    font-family: Century Gothic;
    font-size: 50px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 0;
    margin-top: 270px;
    line-height: 55px; }
  .cnt3_subtitle {
    color: #fff;
    font-family: Century Gothic;
    text-align: center;
    font-size: 28px; }
  a.btn_style {
    width: 420px;
    margin: 0 auto;
    float: none;
    position: relative;
    margin-top: 45px;
    padding-top: 12px;
    padding-bottom: 8px;
    &:before {
      width: 212px;
      height: 40px;
      overflow: hidden;
      bottom: -10px;
      left: 1px;
      z-index: -1; }
    &:after {
      width: 212px;
      height: 40px;
      overflow: hidden;
      bottom: -10px;
      right: 1px;
      z-index: -1; } } }
#content4 {
  position: relative;
  padding-bottom: 163px;
  .cnt4_img1 {
    position: absolute;
    right: 0;
    top: -130px; }
  .cnt4_img2 {
    position: absolute;
    left: 0;
    top: 205px; }
  .c_title {
    margin-top: 95px;
    padding-left: 10px;
    line-height: 40px; }
  hr.yellow {
    margin-left: 5px;
    margin-top: 8px;
    float: left;
    clear: right; }
  .cnt4_content {
    float: left;
    clear: left;
    padding-left: 80px;
    padding-top: 32px;
    ul.cnt4_list {
      list-style-type: decimal;
      font-family: Century Gothic;
      font-size: 22px;
      font-weight: bold;
      color: #ffa900;
      li {
        width: 80%;
        float: left;
        margin-bottom: 10px;

        p {
          font-family: Roboto Regular;
          color: #5d554e;
          font-size: 16px;
          font-weight: normal; } }
      a.btn_style {
        float: left;
        clear: right;
        padding-top: 10px;
        width: 310px;
        margin-bottom: 35px;
        padding-bottom: 5px;
        &:before {
          height: 20px;
          bottom: -10px;
          z-index: -1;
          width: 158px; }
        &:after {
          height: 20px;
          bottom: -10px;
          z-index: -1;
          width: 158px; }
        &:hover {
          text-decoration: none; } }
      a.example_dog {
        font-family: "Roboto Regular";
        color: #2d281b;
        font-size: 16px;
        display: block;
        border-bottom: 2px dotted #2d281b;
        text-transform: uppercase;
        width: auto;
        float: left; } } } }
#content4 .cnt4_content ul.cnt4_list {
  li:last-child {
    width: 115% !important; } }
#content5 {
  background-image: url(../images/bg3.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 128px;
  .cnt5_title {
    font-family: Century Gothic;
    font-size: 30px;
    color: #2d281b;
    text-align: center;
    font-weight: bold;
    padding-left: 18px;
    margin-top: 145px;
    margin-bottom: -15px; }
  .cnt5_subtitle {
    font-family: Century Gothic;
    font-size: 28px;
    text-align: center;
    color: #2d281b;
    padding-left: 18px; }
  .cnt5_form_block {
    padding: 0px;
    margin-top: 50px;
    form {
      margin-left: -10px;
      width: 100%; }
    label {
      font-family: Century Gothic;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: #2d281b;
      padding-left: 30px;
      margin-bottom: 0; }
    .input-group {
      width: 100%; }
    input,textarea {
      width: 110%;
      margin-top: 1px;
      border: 1px solid #c5bdb3;
      border-radius: 0;
      min-height: 40px;
      margin-bottom: 15px;
      padding-left: 30px;
      font-size: 16px;
      font-family: Roboto Regular; }
    textarea {
      min-height: 120px;
      margin-top: 5px;
      margin-bottom: -10px; }
    .check_group {
      p {
        font-family: Roboto Regular;
        font-size: 12px;
        a {
          color: #5d554e; } } }
    button.btn_style {
      float: left;
      margin-top: 12px;
      border: none;
      padding-top: 10px;
      width: 110%;
      max-width: 418px;
      padding-bottom: 5px;
      &:before {
        height: 20px;
        bottom: -10px;
        z-index: -1;
        width: 211px;
        left: 0; }
      &:after {
        height: 20px;
        bottom: -10px;
        z-index: -1;
        width: 211px;
        right: 0; }
      &:hover {
        text-decoration: none; } }
    p.btn_txt {
      font-family: Roboto Regular;
      font-size: 16px;
      text-align: center;
      color: #5d554e;
      margin-top: 85px; } } }
#content6 {
  position: relative;
  .cnt6_img {
    position: absolute;
    left: 0;
    top: -280px; }
  .c_title {
    margin-top: 80px;
    padding-left: 10px;
    line-height: 35px; }
  hr.yellow {
    margin-left: 8px;
    margin-top: 25px; }
  .col-md-6.col-md-offset-6 {
    padding-right: 0; }
  .c_subtitle {
    float: left;
    margin-top: 10px;
    font-family: Century Gothic;
    font-size: 24px;
    color: #2d281b;
    text-align: left;
    padding-left: 8px;
    clear: left; }
  ul.cnt6_list {
    float: left;
    list-style-image: url(../images/list_link.png);
    margin-top: 30px;
    padding-left: 30px;
    li {
      font-family: Roboto Regular;
      font-size: 16px;
      color: #5d554e;
      margin-bottom: 5px; } }
  .cnt6_txt.col-md-10.col-md-offset-1 {
    border-top: 1px solid #ffa900;
    border-bottom: 1px solid #ffa900;
    margin-top: 72px;
    padding-top: 25px;
    line-height: 24px;
    position: relative;
    padding-bottom: 42px;
    p {
      font-family: Century Gothic;
      font-size: 17px;
      color: #2d281b;
      text-align: center; }
    span.yellow_span {
      color: #ec7500;
      text-decoration: underline; } }
  a.btn_style {
    float: left;
    width: 400px;
    margin-top: -28px;
    padding-top: 10px;
    &:before {
      height: 20px;
      bottom: -10px;
      z-index: -1;
      width: 202px;
      left: 0; }
    &:after {
      height: 20px;
      bottom: -10px;
      z-index: -1;
      width: 202px;
      right: 0; }
    &:hover {
      text-decoration: none; } }
  .l_title {
    float: left;
    clear: left;
    width: 100%;
    border-bottom: 1px solid #f0e7dc;
    font-family: Century Gothic;
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    margin-top: 92px;
    color: #2d281b;
    padding-left: 10px; }
  hr.yellow.dop {
    margin-left: 8px;
    margin-top: -4px; }
  .cnt6_blocks {
    float: left;
    margin-top: 35px;
    border-bottom: 1px solid #f0e7dc;
    padding-bottom: 25px;
    margin-bottom: 70px;
    i.icon {
      float: left; }
    p {
      display: block;
      float: left;
      clear: left;
      padding-top: 15px; }
    i.icon.icon-s-m2_icon1 {
      margin-left: 0px; }
    i.icon.icon-s-m2_icon7 {
      margin-left: 10px; }
    i.icon.icon-s-m2_icon3 {
      margin-left: 18px; }
    .block1 {
      padding-left: 10px; }
    .block2 {
      p {
        padding-left: 5px; } }
    .block3 {
      p {
        padding-left: 10px; } }
    .block4 {
      p {
        padding-left: 18px; } } } }
#content7 {
  background-image: url(../images/bg4.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  .cnt7_title {
    font-family: Century Gothic;
    font-size: 50px;
    font-weight: bold;
    color: #2d281b;
    min-height: 100px;
    padding-left: 20px;
    margin-top: 150px;
    line-height: 50px; }
  .cnt7_txt {
    padding-left: 120px;
    margin-top: 40px;
    padding-top: 10px;
    i.icon {
      float: left;
      margin-left: -45px;
      margin-top: -10px; }
    p {
      font-family: Roboto Regular;
      font-size: 16px;
      color: #49473c;
      text-align: left;
      line-height: 22px;
      margin-bottom: 14px; }
    ul.cnt7_list {
      list-style-image: url(../images/list_link.png);
      padding-left: 20px;
      li {
        margin-bottom: 15px;
        font-family: Roboto Regular;
        font-size: 16px;
        color: #49473c;
        text-align: left;
        line-height: 22px; } }
    span.ye_txt {
      color: #ffa900;
      font-size: 30px; }
    p.autor {
      margin-top: 35px;
      margin-bottom: 0; }
    p.ing {
      margin-bottom: 60px; }
    p.autor, p.ing {
      font-family: Century Gothic;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      color: #2d281b; } } }
#content8 {
  .c_title {
    margin-top: 105px;
    padding-left: 10px;
    line-height: 35px; }
  hr.yellow {
    margin-top: 12px;
    margin-left: -4px; }
  #answers2 {
    margin-top: 85px;
    margin-bottom: 200px; } }
#content9 {
  background-image: url(../images/bg5.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 104px;
  .cnt9_title {
    font-family: Century Gothic;
    font-size: 30px;
    color: #2d281b;
    text-align: center;
    font-weight: bold;
    padding-left: 18px;
    margin-top: 145px;
    margin-bottom: -15px; }
  .cnt9_subtitle {
    font-family: Century Gothic;
    font-size: 28px;
    text-align: center;
    color: #2d281b;
    padding-left: 18px; }
  .cnt9_form_block {
    padding: 0px;
    margin-top: 50px;
    form {
      margin-left: -10px;
      width: 100%; }
    label {
      font-family: Century Gothic;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: #2d281b;
      padding-left: 30px;
      margin-bottom: 0; }
    .input-group {
      width: 100%; }
    input,textarea {
      width: 110%;
      margin-top: 1px;
      border: 1px solid #c5bdb3;
      border-radius: 0;
      min-height: 40px;
      margin-bottom: 15px;
      padding-left: 30px;
      font-size: 16px;
      font-family: Roboto Regular; }
    textarea {
      min-height: 120px;
      margin-top: 5px;
      margin-bottom: -10px; }
    .check_group {
      p {
        font-family: Roboto Regular;
        font-size: 12px;
        a {
          color: #5d554e; } } }
    button.btn_style {
      float: left;
      margin-top: 12px;
      border: none;
      padding-top: 10px;
      width: 110%;
      max-width: 418px;
      padding-bottom: 5px;
      &:before {
        height: 20px;
        bottom: -10px;
        z-index: -1;
        width: 211px;
        left: 0; }
      &:after {
        height: 20px;
        bottom: -10px;
        z-index: -1;
        width: 211px;
        right: 0; }
      &:hover {
        text-decoration: none; } }
    p.btn_txt {
      font-family: Roboto Regular;
      font-size: 16px;
      text-align: center;
      color: #5d554e;
      margin-top: 85px; } } }
#content10 {
  background-color: #ae957f;
  .c_title {
    color: #fff;
    margin-top: 75px;
    padding-left: 10px; }
  .cnt10_content {
    float: left;
    clear: left;
    margin-top: 32px; }
  hr.yellow {
    margin-top: 3px;
    margin-left: 5px;
    border-top: 0; }
  .cnt10_left,
  .cnt10_right {
    background-color: #fff;
    padding-top: 32px;
    min-height: 585px;
    margin-bottom: 75px; }
  .cnt10_right {
    box-shadow: 100px 0px 40px -90px #eee inset;
    padding-left: 85px; }
  ul.cnt10_list {
    list-style-type: none;
    i.icon {
      float: left;
      text-align: center;
      font-style: normal;
      padding-top: 5px; }
    li {
      float: left;
      clear: left;
      margin-bottom: 40px; } }
  .cnt10_left {
    padding-left: 66px; }
  .l_title {
    font-family: Roboto Regular;
    line-height: 22px;
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
    padding-right: 20px; }
  a.btn_style {
    float: left;
    margin-top: 20px;
    padding-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 5px; } }

#content11 {
  position: relative;
  #map {
    height: 830px;
    width: 100%;
    background-color: #aaa; }
  .map_descr {
    position: absolute;
    background-image: url(../images/map_descr.png);
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    background-size: cover;
    top: 160px;
    width: 720px;
    padding: 110px 150px;
    padding-bottom: 150px;
    left: 350px; }
  .c_title {
    text-align: center; }
  hr.yellow {
    margin: 0 auto;
    float: none;
    margin-top: 14px; }
  i.icon {
    float: left;
    margin-right: 10px;
    margin-top: -5px; }
  p.adress {
    padding-left: 30px;
    margin-top: 20px;
    font-family: Century Gothic;
    font-size: 16px;
    font-weight: bold;
    color: #2d281b;
    text-transform: uppercase;
    margin-bottom: 0; }
  p.number {
    font-family: Century Gothic;
    font-size: 26px;
    font-weight: bold;
    color: #2d281b; }
  p.question {
    text-align: center;
    font-family: Roboto Regular;
    color: #5d554e;
    font-size: 16px;
    margin-top: 18px; }
  a.btn_style {
    float: none;
    margin: 0 auto;
    padding-bottom: 2px;
    padding-top: 8px; } }


footer {
  background-image: url(../images/footer_bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  background-size: cover;
  padding-bottom: 20px;
  .container {
    padding-right: 0; }
  .f_logo {
    padding-top: 11px;
    padding-left: 10px; }
  .f_text {
    padding-left: 15px;
    padding-right: 0;
    padding-top: 30px;
    p {
      color: #5d554e;
      font-size: 16px;
      text-align: center; } }
  .f_callback {
    padding-right: 0;
    float: right;
    margin-left: -5px;
    a.back_call {
      display: block;
      float: right;
      position: relative;
      background-color: #ffa900;
      width: 205px;
      text-align: center;
      margin-top: -15px;
      padding-top: 10px;

      z-index: 1;
      padding-bottom: 5px;
      &:before {
          content: "";
          display: block;
          width: 105px;
          height: 10px;
          position: absolute;
          overflow: hidden;
          bottom: -5px;
          -webkit-transform: rotate(20deg);
          -ms-transform: rotate(20deg);
          transform: rotate(5deg);
          background-color: #ffa900;
          z-index: -1; }
      &:after {
        content: "";
        display: block;
        width: 105px;
        height: 10px;
        position: absolute;
        overflow: hidden;
        bottom: -5px;
        right: 0;
        -webkit-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        transform: rotate(-5deg);
        background-color: #ffa900;
        z-index: -1; }
      &:hover {
        text-decoration: none;
        background-color: #ffc148;
        &:after {
          background-color: #ffc148; }
        &:before {
          background-color: #ffc148; } } } }
  .f_block {
    padding: 0; }
  .f_number {
    float: right;
    padding-right: 0px;
    padding-top: 13px;
    a {
      color: #000;
      font-family: Century Gothic;
      font-size: 26px;
      font-weight: bold;
      text-decoration: none; } }
  .f_info {
    float: left;
    clear: left;
    padding-left: 10px;
    margin-top: 18px;
    p {
      font-family: "Roboto Regular";
      font-size: 13px;
      color: #5d554e;
      line-height: 18px;
      text-align: left;
      margin-bottom: 0; } }
  .f_orange {
    margin-top: 50px;
    padding-right: 55px;
    a {
      text-decoration: none;
      color: #5d554e;
      font-family: Roboto Regular;
      font-size: 12px;
      text-align: center;
      p {
        margin-bottom: 0; }
      img {
        display: block;
        margin: 0 auto; } } } }
/* modal */
.modal {
  form {
    width: 100%;
    label {
      font-family: Century Gothic;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: #2d281b;
      padding-left: 30px;
      margin-bottom: 0;
      margin-top: 20px; }
    .input-group {
      width: 100%; }
    input,textarea {
      width: 100%;
      margin-top: 1px;
      border: 1px solid #c5bdb3;
      border-radius: 0;
      min-height: 40px;
      margin-bottom: 15px;
      padding-left: 30px;
      font-size: 16px;
      font-family: Roboto Regular; }
    textarea {
      min-height: 120px;
      margin-top: 5px;
      margin-bottom: -10px; }
    .check_group {
      p {
        font-family: Roboto Regular;
        font-size: 12px;
        a {
          color: #5d554e; } } }
    button.btn_style {
      float: left;
      margin-top: 12px;
      border: none;
      padding-top: 10px;
      width: 100%;
      max-width: 418px;
      &:before {
        height: 20px;
        bottom: -10px;
        z-index: -1;
        width: 211px;
        left: 0; }
      &:after {
        height: 20px;
        bottom: -10px;
        z-index: -1;
        width: 211px;
        right: 0; }
      &:hover {
        text-decoration: none; } }
    p.btn_txt {
      font-family: Roboto Regular;
      font-size: 14px;
      text-align: center;
      color: #5d554e;
      margin-top: 95px; } } }
.modal-content {
  border: none;
  border-radius: 0;
  background-color: transparent; }
.modal-header {
  border-bottom: none;
  padding-bottom: 0; }
.modal-dialog {
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  background-size: cover;
  width: 100%; }
.modal-body {
  .title {
    font-family: Century Gothic;
    font-size: 30px;
    text-align: center;
    line-height: 38px;
    font-weight: bold;
    color: #2d281b; }
  .subtitle {
    font-family: Century Gothic;
    font-size: 28px;
    text-align: center;
    color: #2d281b;
    line-height: 45px; } }
p.title {
  text-align: center;
  font-family: Century Gothic;
  font-size: 30px;
  font-weight: bold;
  color: #2d281b; }
p.subtitle {
  font-family: Century Gothic;
  color: #2d281b;
  font-size: 28px;
  text-align: center; }
#thanks_modal {
  .modal-dialog {
    max-width: 650px;
    background-image: url(../images/thanks_bg.jpg); }
  p.title {
    margin-top: 35px; }
  p.subtitle {
    padding-bottom: 80px; } }
#back_call_modal {
  .modal-dialog {
    background-image: url(../images/modal_back.jpg);
    max-width: 1125px; }
  .modal-body {
    padding-left: 50%;
    padding-right: 100px;
    padding-top: 40px; }
  form button.btn_style {
    max-width: 100% !important;
    width: 100%;
    &:before {
      width: 232px; }
    &:after {
      width: 232px; } }
  p.btn_txt {
    padding-bottom: 100px; } }
#spor_modal, #example_modal, #example2_modal, #example3_modal {
  .modal-dialog {
    background-image: url(../images/modal_bg1.jpg);
    max-width: 1125px; }
  .modal-body {
    padding-left: 48%;
    padding-right: 100px;
    padding-top: 40px; }
  form button.btn_style {
    max-width: 100% !important;
    width: 100%;
    &:before {
      width: 245px; }
    &:after {
      width: 245px; } }
  p.btn_txt {
    padding-bottom: 75px; } }
#example_modal {
  p.btn_txt {
    padding-bottom: 20px; } }
#example2_modal, #example3_modal {
  p.btn_txt {
    padding-bottom: 125px; } }
#example4_modal {
  .modal-dialog {
    background-image: url(../images/modal_bg2.jpg);
    max-width: 1125px; }
  .modal-body {
    padding-left: 48%;
    padding-right: 100px;
    padding-top: 40px; }
  form button.btn_style {
    max-width: 100% !important;
    width: 100%;
    &:before {
      width: 245px; }
    &:after {
      width: 245px; } }
  p.btn_txt {
    padding-bottom: 20px; } }


/* adaptive */
@media (width: 1600px) {
  #content1 .cnt1_img2 img {
    width: 70%; }
  #content1 .cnt1_img {
    text-align: right;
    img {
      width: 80%; } }
  #content3 .cnt3_img {
    text-align: left;
    img {
      width: 80%; } }
  #content4 .cnt4_img1 {
    text-align: right;
    img {
      width: 85%; } }
  #content4 .cnt4_img2 {
    img {
      width: 65%; } }
  #content6 .cnt6_img {
    top: -200px;
    img {
      width: 80%; } } }
@media (width: 1400px) {
  #content1 .cnt1_img2 img {
    width: 50%; }
  #content1 .cnt1_img {
    text-align: right;
    img {
      width: 70%; } }
  #content3 .cnt3_img {
    text-align: left;
    img {
      width: 60%; } }
  #content4 .cnt4_img1 {
    text-align: right;
    img {
      width: 75%; } }
  #content4 .cnt4_img2 {
    img {
      width: 45%; } }
  #content6 .cnt6_img {
    top: -160px;
    img {
      width: 70%; } } }
@media (min-width: 1336px) and (max-width: 1366px) {
  #content1 .cnt1_img2 img {
    width: 45%; }
  #content1 .cnt1_img {
    text-align: right;
    img {
      width: 70%; } }
  #content3 .cnt3_img {
    text-align: left;
    img {
      width: 60%; } }
  #content4 .cnt4_img1 {
    text-align: right;
    img {
      width: 75%; } }
  #content4 .cnt4_img2 {
    img {
      width: 40%; } }
  #content6 .cnt6_img {
    top: -100px;
    img {
      width: 70%; } } }
@media (width: 1280px) {
  #content1 .cnt1_img2 img {
    width: 35%; }
  #content1 .cnt1_img {
    text-align: right;
    img {
      width: 65%; } }
  #content3 .cnt3_img {
    text-align: left;
    img {
      width: 55%; } }
  #content4 .cnt4_img1 {
    text-align: right;
    img {
      width: 70%; } }
  #content4 .cnt4_img2 {
    img {
      width: 30%; } }
  #content6 .cnt6_img {
    top: -50px;
    img {
      width: 70%; } }
  .owl-prev {
    float: left;
    margin-left: -50px; }
  .owl-next {
    float: right;
    margin-right: -50px; } }
@media (width: 1024px) {
  #content8 #answers2, #content2 #answers {
    width: 90%;
    margin: 0 5%;
    margin-top: 85px;
    margin-bottom: 100px; }
  #content11 .map_descr {
    left: 0; }
  header .h_text {
    padding-left: 0;
    padding-right: 0;
    padding-top: 25px;
    margin-left: 12%;
    width: 40%; }
  #content1 .cnt1_img2 {
    display: none; }
  #content1 .cnt1_img img {
    width: 60%;
    text-align: right;
    right: 0;
    float: right; }
  #content1 .cnt1_blocks i.icon {
    margin-left: 0 !important; }
  #content1 .cnt1_blocks .block1 .col-md-9,
  #content1 .cnt1_blocks .block4 .col-md-9,
  #content1 .cnt1_blocks .block3 .col-md-9,
  #content1 .cnt1_blocks .block6 .col-md-9 {
    padding-left: 15px; }
  .owl-prev {
    float: left;
    margin-left: -50px; }
  .owl-next {
    float: right;
    margin-right: -50px; }
  #content2 .s_block_right ul.s_list2,
  #content2 .s_block_right .block_bottom ul.s_list3 {
    padding-left: 30px; }
  #content2 .s_block_left ul.s_list {
    padding-left: 0; }
  #content2 .s_block_right .block_bottom .s_title,
  #content2 .s_block_right .s_title {
    padding-left: 75px; }
  #content2 .s_block_left .s_title {
    padding-left: 45px; }
  #content2 .s_block_left {
    padding-bottom: 90px; }
  #content3 .cnt3_title {
    width: 90%;
    margin: 0 5%;
    margin-top: 200px; }
  #content3 .cnt3_subtitle {
    width: 90%;
    margin: 0 5%; }
  #content3 .cnt3_img {
    position: absolute;
    top: -220px;
    left: -160px;
    img {
      width: 60%; } }
  #content4 .cnt4_img2 {
    display: none; }
  #content4 .cnt4_img1 {
    top: 65px;
    img {
      width: 60%;
      float: right; } }
  #content4 .cnt4_content {
    padding-left: 30px; }
  #content5 .cnt5_form_block,
  #content9 .cnt9_form_block {
    margin-left: 30%;
    width: 40%; }
  #content6 .cnt6_img {
    top: 0;
    img {
      width: 55%; } }
  #content7 .cnt7_title {
    font-size: 45px; }
  #content6 .cnt6_txt {
    margin: 0 auto;
    width: 100%; }
  #content6 a.btn_style {
    float: left;
    width: 42%;
    padding-top: 10px;
    margin: 0 31%;
    margin-top: -28px; }
  #content10 .cnt10_left {
    padding-left: 30px; }
  #content10 .cnt10_right {
    padding-left: 45px; }
  #content10 .cnt10_right,
  #content10 .cnt10_left {
    min-height: 625px; }
  footer .f_text {
    padding-left: 0;
    padding-right: 0;
    padding-top: 25px;
    margin-left: 12%;
    width: 40%; }
  footer .f_info {
    width: 45%; }
  footer .f_orange {
    margin-left: 18%; }
  .modal-dialog {
    -webkit-background-size: 100%;
    background-size: 100%; }
  .modal-body .title {
    padding: 26px; }
  .modal-body .subtitle {
    font-size: 24px;
    line-height: 32px; }
  #thanks_modal p.subtitle {
    padding-bottom: 30px; }
  #spor_modal .modal-body, #example_modal .modal-body, #example2_modal .modal-body, #example3_modal .modal-body {
    padding-left: 46%;
    padding-right: 60px;
    padding-top: 40px; }
  #example2_modal p.btn_txt, #example3_modal p.btn_txt {
    padding-bottom: 0; }
  #spor_modal .modal-body, #example_modal .modal-body, #example2_modal .modal-body, #example3_modal .modal-body {
    padding-top: 10px; }
  #spor_modal form button.btn_style:after, #example_modal form button.btn_style:after, #example2_modal form button.btn_style:after, #example3_modal form button.btn_style:after {
    width: 248px; }
  #spor_modal form button.btn_style:before, #example_modal form button.btn_style:before, #example2_modal form button.btn_style:before, #example3_modal form button.btn_style:before {
    width: 248px; }
  #back_call_modal p.btn_txt {
    padding-bottom: 0; }
  #back_call_modal .modal-body {
    padding-top: 0; }
  #back_call_modal .modal-body .title {
    padding-top: 0;
    padding: 20px 0 15px 0; }
  #back_call_modal form button.btn_style:before,
  #back_call_modal form button.btn_style:after {
    width: 208px; }
  #back_call_modal .modal-dialog {
    background-position: bottom; }
  #example4_modal form button.btn_style:before,
  #example4_modal form button.btn_style:after {
    width: 218px; }
  #example4_modal .modal-body .title {
    padding: 0; }
  #example4_modal .modal-body {
    padding-top: 0; }
  #spor_modal .modal-body .title {
    padding: 0;
    padding-top: 15px; }
  #spor_modal p.btn_txt {
    padding-bottom: 55px; }
  #example_modal p.btn_txt {
    padding-bottom: 0; } }
@media (width: 768px) {

  #back_call_modal p.btn_txt {
    padding-bottom: 0; }
  #content11 .map_descr {
    left: -175px; }
  #content6 .cnt6_img img {
    width: 100%; }
  #content2 #answers, #content8 #answers2 {
    width: 85%;
    float: none;
    clear: left;
    margin: 0 auto; }
  #content9 .cnt9_form_block input, #content9 .cnt9_form_block textarea {
    width: 100%; }
  #content5 .cnt5_form_block input, #content5 .cnt5_form_block textarea {
    width: 100%; }
  #content4 .cnt4_content ul.cnt4_list li:last-child {
    width: 100% !important; }
  header {
    background-position: 65% 0% !important; }
  header .h_logo {
    float: left; }
  header .h_text {
    padding-top: 20px; }
  body header .title {
    text-align: center;
    margin-top: 120px; }
  body header .subtitle {
    text-align: center; }
  body header .h_text p {
    text-align: right; }
  body header .h_list {
    padding-left: 18px;
    width: 52%;
    margin: 0 auto;
    margin-top: 45px; }
  #content1 .cnt1_img2 {
    display: none; }
  #content1 .content {
    float: left;
    margin-top: 55px; }
  #content1 .cnt1_blocks {
    float: left;
    margin-top: 20px;
    padding-top: 20px;
    .row {
      width: 50%;
      float: left;
      margin: 0; } }
  #content1 .cnt1_img img {
    width: 50%;
    float: right; }
  #content1 .c_title {
    margin-top: 40px;
    padding-left: 10px;
    width: 60%; }
  #content1 .row.border_top {
    border-top: none;
    padding-top: 0;
    margin: 0 auto; }
  #content1 .cnt1_block.block5, #content1 .cnt1_blocks .block6 .col-md-9, #content1 .cnt1_blocks .block3 .col-md-9, .cnt1_block.block2 {
    padding-left: 5px; }
  #content1 .cnt1_blocks .block6 i.icon, #content1 .cnt1_blocks .block3 i.icon {
    margin-left: 5px; }
  #content1 .cnt1_blocks p.b_title {
    padding-top: 15px; }
  .owl-next {
    margin-right: -50px; }
  .owl-prev {
    margin-left: -50px; }
  .slide {
    background-color: #fff; }
  #content2 .s_block_right .block_top {
    box-shadow: none;
    padding-bottom: 0; }
  #content2 .s_block_right .block_bottom .s_title {
    margin-top: 0;
    padding-top: 20px; }
  #content2 .block_top, #content2 .block_bottom {
    padding-bottom: 25px; }
  #content2 .block_bottom {
    background-color: rgba(199, 178, 161, 0.53); }
  #content3 .cnt3_img img {
    width: 45%; }
  #content3 .cnt3_title {
    font-size: 42px; }
  #content3 .cnt3_subtitle {
    font-size: 25px; }
  #content3 {
    background-position: 60% 0%;
    padding-bottom: 210px; }
  #content4 .cnt4_img1 img {
    width: 55%;
    float: right; }
  #content4 .cnt4_img1 {
    top: -50px;
    right: 0px; }
  #content4 .cnt4_img2 {
    display: none; }
  #content4 .c_title {
    width: 50%;
    font-size: 30px; }
  #content4 .cnt4_content {
    padding-left: 40px; }
  #content4 .cnt4_content ul.cnt4_list li {
    width: 65%; }
  #content4 {
    padding-bottom: 50px; }
  #content5 .cnt5_title {
    width: 70%;
    margin: 0 auto;
    margin-top: 100px; }

  #content6 .cnt6_img {
    img {
      width: 60%; } }
  #content6 .cnt6_img {
    top: -135px; }
  #content5 .cnt5_form_block {
    padding: 0px;
    width: 60%;
    margin: 0 auto;
    margin-top: 50px; }
  #content5 .cnt5_form_block form {
    margin-left: 0; }
  #content5 .cnt5_form_block button.btn_style {
    max-width: 430px; }
  #content5 .cnt5_form_block button.btn_style:before,
  #content5 .cnt5_form_block button.btn_style:after {
    width: 218px; }
  #content6 .c_title {
    margin-top: 250px; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 {
    float: left;
    margin-top: 0; }
  #content6 a.btn_style {
    float: left;
    width: 55%;
    margin-top: -28px;
    padding-top: 10px;
    margin: 0 22.5%; }
  #content6 .l_title {
    margin-top: 50px; }
  #content6 .cnt6_blocks {
    margin-top: 0;
    margin-bottom: 30px; }
  #content6 .cnt6_blocks .cnt6_block {
    width: 33.3%;
    float: left;
    i.icon {
      margin: 0 auto;
      float: none;
      text-align: center;
      display: block; }
    p {
      text-align: center; } }
  #content6 .cnt6_blocks .block2 {
    text-align: center;
    p {
      float: none; } }

  #content6 .cnt6_block.block1 {
    padding-right: 0; }
  #content7 .cnt7_title {
    margin-top: 50px; }
  #content7 {
    background-position: 100% 0; }
  #content8 .c_title {
    margin-top: 40px; }
  #content2 #answers, #content8 #answers2 {
    width: 85%;
    float: none;
    clear: left;
    margin: 25px auto;
    margin-top: 80px; }
  .owl-nav {
    height: 1px;
    position: absolute;
    width: 100%;
    top: 35%; }
  #content9 .cnt9_form_block {
    padding: 0px;
    width: 60%;
    margin: 0 auto;
    margin-top: 50px; }
  #content9 .cnt9_form_block form {
    margin-left: 0; }
  #content9 .cnt9_form_block button.btn_style {
    max-width: 430px; }
  #content9 .cnt9_form_block button.btn_style:before,
  #content9 .cnt9_form_block button.btn_style:after {
    width: 218px; }
  #content9 .cnt9_title {
    width: 70%;
    margin: 0 auto;
    margin-top: 60px; }
  #content9 .cnt9_subtitle {
    width: 70%;
    margin: 0 auto; }
  #content9 .cnt9_subtitle,
  #content9 .cnt9_title,
  #content9 .cnt5_subtitle,
  #content9 .cnt5_title {
    padding-left: 0; }
  #content10 .cnt10_left, #content10 .cnt10_right {
    background-color: #fff;
    padding-top: 32px;
    min-height: 730px;
    margin-bottom: 75px;
    float: left;
    width: 50%;
    padding-left: 25px !important;
    box-shadow: none !important; }
  footer .f_logo {
    float: left; }
  footer .f_text {
    padding-top: 20px;
    float: right;
    p {
      text-align: right; } }
  footer .f_callback {
    margin-top: 30px; }
  footer .f_orange {
    margin-top: 20px;
    padding-right: 55px;
    float: left; }
  footer .container {
    padding-right: 25px; }
  #back_call_modal .modal-body {
    padding-left: 45%;
    padding-right: 35px;
    padding-top: 20px;
    padding-bottom: 20px; }

  #back_call_modal .modal-dialog {
    background-image: url(../images/modal_back.jpg);
    max-width: 100%;
    background-position: 0% 55%;
    background-size: 100%;
    background-color: #faf7e8; }
  #back_call_modal .modal-dialog .modal-body .subtitle {
    font-family: Century Gothic;
    font-size: 22px;
    text-align: center;
    color: #2d281b;
    line-height: 25px;
    margin-top: 20px; }
  #back_call_modal .modal-body .title {
    font-family: Century Gothic;
    font-size: 26px;
    text-align: center;
    line-height: 26px;
    font-weight: bold;
    color: #2d281b;
    margin-top: 10px; }
  #back_call_modal .modal-header {
    padding-bottom: 0;
    height: 10px;
    padding-top: 15px;
    padding-right: 15px; }
  #back_call_modal form button.btn_style:after,
  #back_call_modal form button.btn_style:before {
    width: 195px; }
  #spor_modal .modal-body, #example_modal .modal-body, #example2_modal .modal-body, #example3_modal .modal-body {
    padding-left: 45%;
    padding-right: 25px;
    padding-top: 40px; }
  #spor_modal p.btn_txt, #example_modal p.btn_txt, #example2_modal p.btn_txt, #example3_modal p.btn_txt {
    padding-bottom: 0; }
  .modal-body .title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px; }
  .modal-body .subtitle {
    font-family: Century Gothic;
    font-size: 20px;
    text-align: center;
    color: #2d281b;
    line-height: 25px; }
  #spor_modal .modal-dialog, #example_modal .modal-dialog, #example2_modal .modal-dialog, #example3_modal .modal-dialog {
    background-image: url(../images/modal_bg1.jpg);
    max-width: 1125px;
    background-position: center;
    background-size: 100%;
    background-color: #fcf6e6; }
  #spor_modal form button.btn_style:after, #example_modal form button.btn_style:after, #example2_modal form button.btn_style:after, #example3_modal form button.btn_style:after {
    width: 201px; }
  #spor_modal form button.btn_style:before, #example_modal form button.btn_style:before, #example2_modal form button.btn_style:before, #example3_modal form button.btn_style:before {
    width: 201px; }
  #example4_modal .modal-body {
    padding-left: 45%;
    padding-right: 60px;
    padding-top: 40px; }
  #example4_modal form button.btn_style:before,
  #example4_modal form button.btn_style:after {
    width: 185px; } }




@media (min-width: 568px) and (max-width: 740px) {

  #back_call_modal p.btn_txt {
    padding-bottom: 0; }
  #content11 .map_descr {
    left: -175px; }
  #content6 .cnt6_img img {
    width: 100%; }
  #content2 #answers, #content8 #answers2 {
    width: 85%;
    float: none;
    clear: left;
    margin: 0 auto; }
  #content9 .cnt9_form_block input, #content9 .cnt9_form_block textarea {
    width: 100%; }
  #content5 .cnt5_form_block input, #content5 .cnt5_form_block textarea {
    width: 100%; }
  #content4 .cnt4_content ul.cnt4_list li:last-child {
    width: 100% !important; }
  header {
    background-position: 65% 0% !important; }
  header .h_logo {
    float: left;
    padding-left: 0 !important; }
  header .h_text {
    padding-top: 20px !important; }
  body header .title {
    text-align: center;
    margin-top: 120px; }
  body header .subtitle {
    text-align: center; }
  body header .h_text p {
    text-align: right;
    font-size: 14px; }
  body header .h_list {
    padding-left: 18px;
    width: 52%;
    margin: 0 auto;
    margin-top: 45px; }
  #content1 .cnt1_img2 {
    display: none; }
  #content1 .content {
    float: left;
    margin-top: 55px; }
  #content1 .cnt1_blocks {
    float: left;
    margin-top: 20px;
    padding-top: 20px;
    .row {
      width: 50%;
      float: left;
      margin: 0; } }
  #content1 .cnt1_img img {
    width: 50%;
    float: right; }
  #content1 .c_title {
    margin-top: 40px;
    padding-left: 10px;
    width: 60%; }
  #content1 .row.border_top {
    border-top: none;
    padding-top: 0;
    margin: 0 auto; }
  #content1 .cnt1_block.block5, #content1 .cnt1_blocks .block6 .col-md-9, #content1 .cnt1_blocks .block3 .col-md-9, .cnt1_block.block2 {
    padding-left: 5px; }
  #content1 .cnt1_blocks .block6 i.icon, #content1 .cnt1_blocks .block3 i.icon {
    margin-left: 5px; }
  #content1 .cnt1_blocks p.b_title {
    padding-top: 15px; }
  .owl-next {
    margin-right: -50px; }
  .owl-prev {
    margin-left: -50px; }
  .slide {
    background-color: #fff; }
  #content2 .s_block_right .block_top {
    box-shadow: none;
    padding-bottom: 0; }
  #content2 .s_block_right .block_bottom .s_title {
    margin-top: 0;
    padding-top: 20px; }
  #content2 .block_top, #content2 .block_bottom {
    padding-bottom: 25px; }
  #content2 .block_bottom {
    background-color: rgba(199, 178, 161, 0.53); }
  #content3 .cnt3_img img {
    width: 45%; }
  #content3 .cnt3_title {
    font-size: 42px; }
  #content3 .cnt3_subtitle {
    font-size: 25px; }
  #content3 {
    background-position: 60% 0%;
    padding-bottom: 210px; }
  #content4 .cnt4_img1 img {
    width: 55%;
    float: right; }
  #content4 .cnt4_img1 {
    top: -50px;
    right: 0px; }
  #content4 .cnt4_img2 {
    display: none; }
  #content4 .c_title {
    width: 50%;
    font-size: 30px; }
  #content4 .cnt4_content {
    padding-left: 40px; }
  #content4 .cnt4_content ul.cnt4_list li {
    width: 95%; }
  #content4 {
    padding-bottom: 50px; }
  #content5 .cnt5_title {
    width: 70%;
    margin: 0 auto;
    margin-top: 100px; }

  #content6 .cnt6_img {
    img {
      width: 60%; } }
  #content6 .cnt6_img {
    top: -135px; }
  #content5 .cnt5_form_block {
    padding: 0px;
    width: 60%;
    margin: 0 auto;
    margin-top: 50px; }
  #content5 .cnt5_form_block form {
    margin-left: 0; }
  #content5 .cnt5_form_block button.btn_style {
    max-width: 430px; }
  #content5 .cnt5_form_block button.btn_style:before,
  #content5 .cnt5_form_block button.btn_style:after {
    width: 218px; }
  #content6 .c_title {
    margin-top: 250px; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 {
    float: left;
    margin-top: 0; }
  #content6 a.btn_style {
    float: left;
    width: 55%;
    margin-top: -28px;
    padding-top: 10px;
    margin: 0 22.5%; }
  #content6 .l_title {
    margin-top: 50px; }
  #content6 .cnt6_blocks {
    margin-top: 0;
    margin-bottom: 30px; }
  #content6 .cnt6_blocks .cnt6_block {
    width: 33.3%;
    float: left;
    i.icon {
      margin: 0 auto;
      float: none;
      text-align: center;
      display: block; }
    p {
      text-align: center; } }
  #content6 .cnt6_blocks .block2 {
    text-align: center;
    p {
      float: none; } }

  #content6 .cnt6_block.block1 {
    padding-right: 0; }
  #content7 .cnt7_title {
    margin-top: 50px; }
  #content7 {
    background-position: 100% 0; }
  #content8 .c_title {
    margin-top: 40px; }
  .modal form .check_group p {
    font-family: Roboto Regular;
    font-size: 12px;
    float: left;
    width: 90%; }
  #content2 #answers, #content8 #answers2 {
    width: 85%;
    float: none;
    clear: left;
    margin: 25px auto;
    margin-top: 80px; }
  .owl-nav {
    height: 1px;
    position: absolute;
    width: 100%;
    top: 35%; }
  #content9 .cnt9_form_block {
    padding: 0px;
    width: 60%;
    margin: 0 auto;
    margin-top: 50px; }
  #content9 .cnt9_form_block form {
    margin-left: 0; }
  #content9 .cnt9_form_block button.btn_style {
    max-width: 430px; }
  #content9 .cnt9_form_block button.btn_style:before,
  #content9 .cnt9_form_block button.btn_style:after {
    width: 218px; }
  #content9 .cnt9_title {
    width: 70%;
    margin: 0 auto;
    margin-top: 60px; }
  #content9 .cnt9_subtitle {
    width: 70%;
    margin: 0 auto; }
  #content9 .cnt9_subtitle,
  #content9 .cnt9_title,
  #content9 .cnt5_subtitle,
  #content9 .cnt5_title {
    padding-left: 0; }
  #content10 .cnt10_left, #content10 .cnt10_right {
    background-color: #fff;
    padding-top: 32px;
    min-height: 730px;
    margin-bottom: 75px;
    float: left;
    width: 50%;
    padding-left: 25px !important;
    box-shadow: none !important; }
  footer .f_logo {
    float: left; }
  footer .f_text {
    padding-top: 20px;
    float: right;
    p {
      text-align: right; } }
  footer .f_callback {
    margin-top: 30px; }
  footer .f_orange {
    margin-top: 20px;
    padding-right: 55px;
    float: left; }
  footer .container {
    padding-right: 25px; }
  #back_call_modal .modal-body {
    padding-left: 45%;
    padding-right: 35px;
    padding-top: 20px;
    padding-bottom: 20px; }

  #back_call_modal .modal-dialog {
    background-image: url(../images/modal_back.jpg);
    max-width: 100%;
    background-position: 0% 55%;
    background-size: 100%;
    background-color: #faf7e8; }
  #back_call_modal .modal-dialog .modal-body .subtitle {
    font-family: Century Gothic;
    font-size: 22px;
    text-align: center;
    color: #2d281b;
    line-height: 25px;
    margin-top: 20px; }
  #back_call_modal .modal-body .title {
    font-family: Century Gothic;
    font-size: 26px;
    text-align: center;
    line-height: 26px;
    font-weight: bold;
    color: #2d281b;
    margin-top: 10px; }
  #back_call_modal .modal-header {
    padding-bottom: 0;
    height: 10px;
    padding-top: 15px;
    padding-right: 15px; }
  #back_call_modal form button.btn_style:after,
  #back_call_modal form button.btn_style:before {
    width: 195px; }
  #spor_modal .modal-body, #example_modal .modal-body, #example2_modal .modal-body, #example3_modal .modal-body {
    padding-left: 45%;
    padding-right: 25px;
    padding-top: 40px; }
  #spor_modal p.btn_txt, #example_modal p.btn_txt, #example2_modal p.btn_txt, #example3_modal p.btn_txt {
    padding-bottom: 0; }
  .modal-body .title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px; }
  .modal-body .subtitle {
    font-family: Century Gothic;
    font-size: 20px;
    text-align: center;
    color: #2d281b;
    line-height: 25px; }
  #spor_modal .modal-dialog, #example_modal .modal-dialog, #example2_modal .modal-dialog, #example3_modal .modal-dialog {
    background-image: url(../images/modal_bg1.jpg);
    max-width: 1125px;
    background-position: center;
    background-size: 100%;
    background-color: #fcf6e6; }
  #spor_modal form button.btn_style:after, #example_modal form button.btn_style:after, #example2_modal form button.btn_style:after, #example3_modal form button.btn_style:after {
    width: 51%; }
  #spor_modal form button.btn_style:before, #example_modal form button.btn_style:before, #example2_modal form button.btn_style:before, #example3_modal form button.btn_style:before {
    width: 51%; }
  #example4_modal .modal-body {
    padding-left: 45%;
    padding-right: 60px;
    padding-top: 40px; }
  #example4_modal form button.btn_style:before,
  #example4_modal form button.btn_style:after {
    width: 51%; } }





















@media (min-width: 320px) and (max-width: 420px) {
  img {
    width: 100%; }
  #content11 .map_descr {
    width: 100%;
    left: 0; }
  .owl-next {
    margin-right: 0; }
  .owl-prev {
    margin-left: 0; }
  #content4 .cnt4_content ul.cnt4_list li:last-child {
    width: 100% !important; }
  #content5 .cnt5_form_block input, #content5 .cnt5_form_block textarea {
    width: 100%; }
  #content5 .cnt5_form_block button.btn_style, #content9 .cnt9_form_block input, #content9 .cnt9_form_block textarea, #content9 .cnt9_form_block button.btn_style {
    width: 100%; }
  #content6 a.btn_style {
    width: 100%; }
  #content4 .cnt4_content {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0; }
  #content3 a.btn_style {
    width: 100%; }









  body header .h_logo {
    img {}
    width: 50%;
    margin: 0 auto;
    display: block;
    padding: 0; }
  #content2 #answers {
    padding: 0;
    float: left;
    clear: left; }
  #content3 .cnt3_title {
    font-size: 45px; }
  body header .container {
    padding-right: 15px; }
  body header .h_text {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px; }
  body header .h_text p {
    font-size: 14px; }
  body header .h_callback {
    float: left;
    margin: 0 auto;
    display: block;
    width: 100%; }

  body header .h_callback a.back_call {
    float: none;
    margin: 0 auto;
    display: block;
    width: 60%; }

  body header .h_number {
    float: none;
    padding-right: 0px;
    padding-top: 13px;
    width: 100%;
    text-align: center; }

  body header .title {
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    padding-left: 0;
    width: 90%;
    margin: 0 auto;
    margin-top: 115px; }
  .c_title {
    font-size: 25px; }
  body header .subtitle {
    padding-left: 0;
    text-align: center;
    font-size: 20px; }
  body header .h_list a.btn_style {
    margin-bottom: 260px; }
  #content1 .c_title {
    margin-top: 265px; }
  #content1 .cnt1_img2 {
    display: none; }
  #content1 .cnt1_blocks {
    float: left;
    margin-top: 0;
    padding-top: 20px; }
  #content1 .content {
    padding: 0 15px;
    float: left;
    margin-top: 0; }
  #content1 .cnt1_blocks .cnt1_block {
    min-height: 160px;
    float: left;
    clear: left;
    padding-left: 0;
    .col-md-3,
    .col-md-9 {
      padding-left: 0 !important; } }
  #content1 .border_top {
    padding-top: 10px; }
  #content1 .cnt1_blocks .block3 .col-md-9,
  #content1 .cnt1_blocks .block4 .col-md-9,
  #content1 .cnt1_blocks .block5 .col-md-9,
  #content1 .cnt1_blocks .block6 .col-md-9 {
    padding-left: 15px; }
  #content1 .cnt1_blocks i.icon {
    margin-left: 0 !important;
    margin-bottom: 10px; }
  #content2 .c_title {
    margin-top: 30px; }
  #content2 .c_title {
    margin-top: 30px;
    font-size: 20px;
    text-align: center;
    padding: 0;
    line-height: 30px; }
  .slide {
    border: 2px solid #eee;
    box-shadow: 5px 10px 30px -11px #eee;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #fff;
    float: left;
    width: 100%;
    margin: 0 auto; }
  #content2 .s_block_left ul.s_list {
    padding-left: 0;
    padding-top: 0; }
  #content2 .s_block_left .s_title {
    padding-left: 0;
    margin-bottom: 30px; }
  #content2 .s_block_right .s_title,
  #content2 .s_block_right .block_bottom .s_title {
    padding-left: 15px; }
  #content2 .s_block_right ul.s_list2 {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px; }
  #content2 .s_block_right .block_bottom ul.s_list3 {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 15px;
    padding-bottom: 15px;
    float: left; }
  #content2 .s_block_right .block_top {
    box-shadow: none; }
  .owl-nav {
    top: 42%; }
  #content3 .cnt3_img {
    display: none; }
  #content2 {
    padding-bottom: 30px; }
  #content3 .cnt3_title {
    font-size: 26px;
    line-height: 32px;
    margin-top: 40px; }
  #content3 {
    background-position: 27% 0; }
  #content3 a.btn_style:after,
  #content3 a.btn_style:before {
    width: 51%; }
  #content3 .cnt3_subtitle {
    font-size: 22px; }
  #content3 {
    margin-bottom: 190px; }
  #content4 .cnt4_img1 {
    position: absolute;
    right: 0;
    top: -405px; }
  #content4 .c_title {
    margin-top: 0;
    line-height: 30px;
    padding-left: 10px; }
  #content4 .cnt4_img2 {
    display: none; }
  #content4 {
    padding-bottom: 30px; }
  #content5 .cnt5_title {
    padding-left: 0;
    margin-top: 60px;
    margin-bottom: 0; }
  #content5 .cnt5_subtitle {
    font-size: 24px;
    padding-left: 0; }
  #content5 .cnt5_form_block form {
    margin-left: 0;
    width: 95%;
    margin: 0 auto; }
  #content5 .cnt5_form_block .check_group p {
    font-family: Roboto Regular;
    font-size: 11px;
    display: block;
    float: left;
    width: 90%; }
  #content5 .cnt5_form_block button.btn_style:before,
  #content5 .cnt5_form_block button.btn_style:after,#content9 .cnt9_form_block button.btn_style:before,
  #content9 .cnt9_form_block button.btn_style:after,
  #content6 a.btn_style:before,
  #content6 a.btn_style:after,
  footer .f_callback a.back_call:after,
  footer .f_callback a.back_call:before {
    width: 51%; }
  #content6 .cnt6_img {
    position: relative;
    left: 0;
    top: -105px;
    margin-bottom: -185px; }
  #content6 .c_title {
    line-height: 30px;
    padding-left: 0;
    margin-top: 100px; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 p {
    float: left; }
  #content6 .l_title {
    font-size: 26px;
    line-height: 30px;
    padding-bottom: 20px; }
  #content6 .cnt6_blocks {
    margin-top: 0; }
  #content6 .cnt6_blocks .cnt6_block {
    float: left;
    width: 100%;
    padding: 0;
    text-align: center; }
  #content6 .cnt6_blocks i.icon {
    float: none;
    margin: 0 auto !important;
    display: block;
    text-align: center; }
  #content6 .cnt6_blocks .block2 p {
    padding-left: 5px;
    width: 100%; }
  #content6 .cnt6_blocks {
    margin-bottom: 20px; }
  #content7 .cnt7_title {
    font-family: Century Gothic;
    font-size: 25px;
    font-weight: bold;
    color: #2d281b;
    min-height: 100px;
    padding-left: 20px;
    margin-top: 30px;
    line-height: 30px; }
  #content7 .cnt7_txt {
    padding-left: 50px;
    margin-top: 10px;
    padding-top: 0; }
  #content7 {
    background-position: right center; }
  #content8 .c_title {
    margin-top: 30px;
    padding-left: 10px;
    line-height: 30px; }
  #content8 #answers2 {
    width: 80%;
    margin: 0 auto;
    margin-top: 65px;
    margin-bottom: 20px;
    .owl-prev {
      margin-left: -45px; }
    .owl-next {
      margin-right: -45px; } }
  #content9 .cnt9_title {
    padding-left: 0;
    margin-top: 60px;
    margin-bottom: 0; }
  #content9 .cnt9_subtitle {
    font-size: 24px;
    padding-left: 0; }
  #content9 .cnt9_form_block form {
    margin-left: 0;
    width: 95%;
    margin: 0 auto; }
  #content9 .cnt9_form_block .check_group p {
    font-family: Roboto Regular;
    font-size: 11px;
    display: block;
    float: left;
    width: 90%; }
  #content9 {
    padding-bottom: 50px; }
  #content10 .c_title {
    margin-top: 20px; }
  #content10 .cnt10_left,
  #content10 .cnt10_right {
    padding: 15px; }
  #content10 .cnt10_content {
    background-color: #fff; }
  #content10 .cnt10_right {
    box-shadow: none; }
  #content11 .map_descr {
    position: relative;
    background-image: none;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    top: 0;
    width: 100%;
    padding: 15px;
    padding-bottom: 40px;
    left: 0; }
  #content11 p.adress {
    padding-left: 0;
    font-size: 15px; }
  footer .container {
    padding-right: 15px; }
  footer .f_text {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px; }
  footer .h_text p {
    font-size: 14px; }
  footer .f_callback {
    float: left;
    margin: 0 auto;
    display: block;
    width: 100%;
    padding: 0; }
  footer .f_callback a.back_call {
    float: none;
    margin: 0 auto;
    display: block;
    width: 60%; }

  footer .f_number {
    float: none;
    padding-right: 0px;
    padding-top: 13px;
    width: 100%;
    text-align: center; }
  .f_logo img {
    width: 55%;
    margin: 0 auto;
    text-align: center;
    display: block; }
  footer .f_text p {
    font-size: 14px; }
  footer .f_info {
    padding: 0; }
  footer .f_orange {
    margin-top: 10px;
    padding-right: 0;
    float: left;
    width: 55%;
    text-align: left;
    padding-left: 0;
    p {
      margin-bottom: 0; }
    a {
      text-align: left; } }
  footer .f_orange a img {
    display: block;
    margin: 0 auto;
    width: 50%;
    float: left; }
  footer .f_info p {
    font-size: 12px; }
  /* modal */
  #back_call_modal .modal-dialog {
    background-image: none;
    max-width: 100%;
    background-color: #faf7e8;
    margin: 0; }
  #back_call_modal .modal-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px; }
  #back_call_modal form button.btn_style:after,
  #back_call_modal form button.btn_style:before {
    width: 51%; }
  .modal-body .subtitle {
    font-size: 18px;
    line-height: 25px; }
  .modal-body .title {
    font-size: 22px;
    line-height: 30px; }
  #back_call_modal p.btn_txt {
    padding-bottom: 20px; }

  #example_modal .modal-dialog,#example4_modal .modal-dialog {
    background-image: none;
    max-width: 100%;
    background-color: #faf7e8;
    margin: 0; }
  #example_modal .modal-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px; }
  #example_modal form button.btn_style:after,
  #example_modal form button.btn_style:before {
    width: 51%; }

  #example2_modal .modal-dialog {
    background-image: none;
    max-width: 100%;
    background-color: #faf7e8;
    margin: 0; }
  #example2_modal .modal-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px; }
  #example2_modal form button.btn_style:after,
  #example2_modal form button.btn_style:before,
  #example4_modal form button.btn_style:after,
  #example4_modal form button.btn_style:before {
    width: 51%; }

  #example3_modal .modal-dialog {
    background-image: none;
    max-width: 100%;
    background-color: #faf7e8;
    margin: 0; }
  #example3_modal .modal-body,#example4_modal .modal-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px; }
  #example3_modal form button.btn_style:after,
  #example3_modal form button.btn_style:before {
    width: 51%; }


  #spor_modal .modal-dialog {
    background-image: none;
    max-width: 100%;
    background-color: #faf7e8;
    margin: 0; }
  #spor_modal .modal-body {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px; }
  #spor_modal form button.btn_style:after,
  #spor_modal form button.btn_style:before {
    width: 51%; }
  .modal form .check_group p {
    font-family: Roboto Regular;
    font-size: 12px;
    float: left;
    width: 90%; } }




@media (width: 320px) {
  body header .h_list {
    padding-left: 0;
    margin-top: 45px; }
  body header .h_callback a.back_call:after,
  body header .h_callback a.back_call:before {
    width: 51%; }
  body header .h_callback {
    padding-left: 0; }
  body header .h_text p {
    font-size: 12px; }
  body header .title {
    font-size: 27px;
    text-align: center;
    line-height: 32px;
    padding-left: 0;
    width: 100%;
    margin: 0 auto;
    margin-top: 115px; }
  body header .subtitle {
    font-size: 18px; }
  body header .h_list p {
    font-size: 14px; }
  body header .h_list a.btn_style {
    margin-bottom: 240px;
    width: 100%;
    margin-left: 0; }
  #content1 .c_title {
    margin-top: 195px;
    font-size: 20px;
    line-height: 10px;
    padding-left: 0;
    p {
      line-height: 25px; } }
  #content1 .cnt1_blocks .block1 .col-md-9 {
    padding-left: 15px; }
  #content2 .c_title {
    font-size: 23px;
    text-align: left;
    padding-right: 35px; }
  hr.yellow {
    width: 30%;
    display: block;
    float: none !important;
    border-bottom: 5px solid #ffa900;
    text-align: center;
    margin-left: 2px !important;
    margin-right: auto; }
  #content2 .slide ul.s_list li,
  #content2 .slide ul.s_list2 li,
  #content2 .slide ul.s_list3 li {
    font-size: 13px; }
  #content2 .slide .s_title {
    font-size: 13px; }
  #content3 .cnt3_title {
    font-size: 20px;
    line-height: 30px;
    margin-top: 40px; }
  #content3 .cnt3_subtitle {
    font-size: 18px; }

  #content4 .c_title {
    margin-top: -100px;
    line-height: 30px;
    padding-left: 0px; }
  #content4 .cnt4_content ul.cnt4_list li p {
    font-size: 13px; }
  #content4 .cnt4_content ul.cnt4_list li {
    width: 100%; }
  #content4 .cnt4_content ul.cnt4_list a.btn_style {
    width: 100%;
    margin-left: -20px;
    margin-bottom: 10px;
    font-size: 15px; }
  #content4 .cnt4_content ul.cnt4_list a.btn_style:before,
  #content4 .cnt4_content ul.cnt4_list a.btn_style:after {
    width: 51%; }
  #content5 .cnt5_form_block .check_group p {
    font-family: Roboto Regular;
    font-size: 12px;
    display: block;
    float: left;
    width: 85%; }
  #content5 .cnt5_form_block p.btn_txt {
    font-family: Roboto Regular;
    font-size: 13px;
    text-align: center;
    color: #5d554e;
    margin-top: 100px; }
  #content6 .c_subtitle {
    font-size: 21px; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 {
    float: left;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0; }
  #content8 #answers2 .owl-next {
    margin-right: -40px; }
  #content8 #answers2 .owl-prev {
    margin-left: -40px; }
  #content5 .cnt5_title {
    font-size: 26px;
    line-height: 30px; }
  #content5 .cnt5_subtitle {
    font-size: 16px; }
  #content6 .col-md-6.col-md-offset-6 {
    padding-right: 0;
    padding-left: 0; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 p {
    text-align: left; }
  #content6 .l_title {
    margin-top: 50px;
    border-bottom: none;
    padding-left: 0;
    padding-bottom: 5px; }
  #content6 .cnt6_blocks {
    border-bottom: none; }
  #content7 .cnt7_txt p {
    font-size: 14px; }
  #content7 .cnt7_txt ul.cnt7_list li {
    font-size: 14px; }
  #content8 .c_title {
    padding-left: 0; }
  #content8 #answers2 {
    margin-top: 25px; }
  #content9 .cnt9_title {
    font-size: 26px;
    line-height: 30px; }
  #content9 .cnt9_subtitle {
    font-size: 20px; }
  #content9 .cnt9_form_block {
    margin-top: 20px; }
  #content9 .cnt9_form_block .check_group p {
    font-size: 13px;
    width: 85%; }
  #content9 .cnt9_form_block p.btn_txt {
    font-size: 14px;
    margin-top: 100px; }
  #content9 {
    padding-bottom: 20px; }
  #content7 .cnt7_title {
    padding-left: 0; }
  #content10 .c_title {
    padding-left: 0;
    line-height: 30px; }
  #content10 .l_title {
    font-size: 14px;
    padding-right: 0;
    text-align: center; }
  .l_descr {
    font-size: 13px; }
  #content10 a.btn_style {
    margin-top: 0;
    margin-left: 0;
    width: 100%; }
  a.btn_style:before, button.btn_style:before,
  a.btn_style:after, button.btn_style:after {
    width: 51%; }
  #content10 ul.cnt10_list li {
    margin: 15px 0; }
  #content10 .cnt10_content {
    margin: 0;
    width: 100%; }
  #content10 .cnt10_right {
    box-shadow: none;
    float: left;
    padding-top: 0;
    margin-bottom: 0; }
  #content11 #map {
    height: 470px; }
  #content11 .c_title {
    text-align: center;
    line-height: 30px; }
  #content11 i.icon {
    float: none;
    margin-right: auto;
    margin-top: auto;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px; }
  #content11 .map_descr {
    padding: 15px 0;
    padding-bottom: 30px; }
  #content11 p.adress {
    text-align: center; }
  #content11 p.number {
    text-align: center; }
  footer .f_text p {
    font-size: 12px; }
  footer .f_info p {
    font-size: 10px; }
  #content10 ul.cnt10_list i.icon {
    float: none;
    margin: 0 auto;
    margin-top: -20px;
    margin-bottom: 10px; }
  #content10 ul.cnt10_list {
    list-style-type: none;
    padding-left: 0; }
  #content11 hr.yellow {
    margin: 0 auto;
    float: none !important;
    margin-top: 0px;
    width: 100%; }
  #content11 a.btn_style {
    width: 60%; }
  #content1 .cnt1_blocks i.icon {
    margin-bottom: 10px;
    margin: 0 auto !important;
    margin-bottom: 5px !important; }
  #content1 .cnt1_blocks .cnt1_block {
    padding-right: 0; }
  #content1 .cnt1_blocks p.b_title {
    text-align: center; }
  .modal form .check_group p {
    font-family: Roboto Regular;
    font-size: 12px;
    float: left;
    width: 85%; }
  .modal form p.btn_txt {
    font-size: 12px;
    margin-top: 80px;
    padding-bottom: 0 !important; }
  .modal .btn_style a, a.btn_style, button.btn_style {
    font-size: 14px; }
  #example4_modal.modal form p.btn_txt {
    margin-top: 100px; } }



@media (width: 375px) {
  body header .h_list {
    padding-left: 0;
    margin-top: 45px; }
  body header .h_callback a.back_call:after,
  body header .h_callback a.back_call:before {
    width: 51%; }
  body header .h_callback {
    padding-left: 0; }
  body header .h_text p {
    font-size: 12px; }
  body header .title {
    font-size: 27px;
    text-align: center;
    line-height: 32px;
    padding-left: 0;
    width: 100%;
    margin: 0 auto;
    margin-top: 115px; }
  body header .subtitle {
    font-size: 18px; }
  body header .h_list p {
    font-size: 14px; }
  body header .h_list a.btn_style {
    margin-bottom: 240px;
    width: 100%;
    margin-left: 0; }
  #content1 .c_title {
    margin-top: 245px;
    font-size: 20px;
    line-height: 10px;
    padding-left: 0;
    p {
      line-height: 25px; } }
  #content1 .cnt1_blocks .block1 .col-md-9 {
    padding-left: 15px; }
  #content2 .c_title {
    font-size: 24px;
    text-align: left;
    padding-right: 35px; }
  hr.yellow {
    width: 30%;
    display: block;
    float: none !important;
    border-bottom: 5px solid #ffa900;
    text-align: center;
    margin-left: 2px !important;
    margin-right: auto; }
  #content2 .slide ul.s_list li,
  #content2 .slide ul.s_list2 li,
  #content2 .slide ul.s_list3 li {
    font-size: 13px; }
  #content2 .slide .s_title {
    font-size: 13px; }
  #content3 .cnt3_title {
    font-size: 20px;
    line-height: 30px;
    margin-top: 40px; }
  #content3 .cnt3_subtitle {
    font-size: 18px; }

  #content4 .c_title {
    margin-top: -50px;
    line-height: 30px;
    padding-left: 0px; }
  #content4 .cnt4_content ul.cnt4_list li p {
    font-size: 13px; }
  #content4 .cnt4_content ul.cnt4_list li {
    width: 100%; }
  #content4 .cnt4_content ul.cnt4_list a.btn_style {
    width: 100%;
    margin-left: -20px;
    margin-bottom: 10px;
    font-size: 15px; }
  #content4 .cnt4_content ul.cnt4_list a.btn_style:before,
  #content4 .cnt4_content ul.cnt4_list a.btn_style:after {
    width: 51%; }
  #content5 .cnt5_form_block .check_group p {
    font-family: Roboto Regular;
    font-size: 14px;
    display: block;
    float: left;
    width: 85%; }
  #content5 .cnt5_form_block p.btn_txt {
    font-family: Roboto Regular;
    font-size: 13px;
    text-align: center;
    color: #5d554e;
    margin-top: 80px; }
  #content6 .c_subtitle {
    font-size: 21px; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 {
    float: left;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0; }
  #content8 #answers2 .owl-next {
    margin-right: -40px; }
  #content8 #answers2 .owl-prev {
    margin-left: -40px; }
  #content5 .cnt5_title {
    font-size: 26px;
    line-height: 30px; }
  #content5 .cnt5_subtitle {
    font-size: 16px; }
  #content6 .col-md-6.col-md-offset-6 {
    padding-right: 0;
    padding-left: 0; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 p {
    text-align: left; }
  #content6 .l_title {
    margin-top: 50px;
    border-bottom: none;
    padding-left: 0;
    padding-bottom: 5px; }
  #content6 .cnt6_blocks {
    border-bottom: none; }
  #content7 .cnt7_txt p {
    font-size: 14px; }
  #content7 .cnt7_txt ul.cnt7_list li {
    font-size: 14px; }
  #content8 .c_title {
    padding-left: 0; }
  #content10 .cnt10_left, #content10 .cnt10_right {
    margin-bottom: 0; }
  #content8 #answers2 {
    margin-top: 25px; }
  #content9 .cnt9_title {
    font-size: 26px;
    line-height: 30px; }
  #content9 .cnt9_subtitle {
    font-size: 20px; }
  #content9 .cnt9_form_block {
    margin-top: 20px; }
  #content9 .cnt9_form_block .check_group p {
    font-size: 14px;
    width: 85%; }
  #content9 .cnt9_form_block p.btn_txt {
    font-size: 14px;
    margin-top: 100px; }
  #content9 {
    padding-bottom: 20px; }
  #content7 .cnt7_title {
    padding-left: 0; }
  #content10 .c_title {
    padding-left: 0;
    line-height: 30px; }
  #content10 .l_title {
    font-size: 14px;
    padding-right: 0;
    text-align: center; }
  .l_descr {
    font-size: 13px; }
  #content10 a.btn_style {
    margin-top: 0;
    margin-left: 0;
    width: 100%; }
  a.btn_style:before, button.btn_style:before,
  a.btn_style:after, button.btn_style:after {
    width: 51% !important; }
  #content10 ul.cnt10_list li {
    margin: 15px 0;
    width: 100%; }
  #content10 .cnt10_content {
    margin: 0;
    width: 100%; }
  #content10 .cnt10_right {
    box-shadow: none;
    float: left;
    padding-top: 0;
    margin-bottom: 0; }
  #content11 #map {
    height: 470px; }
  #content11 .c_title {
    text-align: center;
    line-height: 30px; }
  #content11 i.icon {
    float: none;
    margin-right: auto;
    margin-top: auto;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px; }
  #content11 .map_descr {
    padding: 15px 0;
    padding-bottom: 30px; }
  #content11 p.adress {
    text-align: center; }
  #content11 p.number {
    text-align: center; }
  footer .f_text p {
    font-size: 12px; }
  footer .f_info p {
    font-size: 10px; }
  #content10 ul.cnt10_list i.icon {
    float: none;
    margin: 0 auto;
    margin-top: -20px;
    margin-bottom: 10px; }
  #content10 ul.cnt10_list {
    list-style-type: none;
    padding-left: 0; }
  #content11 hr.yellow {
    margin: 0 auto;
    float: none !important;
    margin-top: 0px;
    width: 100%; }
  #content11 a.btn_style {
    width: 60%; }
  #content1 .cnt1_blocks i.icon {
    margin-bottom: 10px;
    margin: 0 auto !important;
    margin-bottom: 5px !important; }
  #content1 .cnt1_blocks .cnt1_block {
    padding-right: 0; }
  #content1 .cnt1_blocks p.b_title {
    text-align: center; }
  .modal form .check_group p {
    font-family: Roboto Regular;
    font-size: 12px;
    float: left;
    width: 90%; }
  .modal form p.btn_txt {
    font-size: 12px;
    margin-top: 80px;
    padding-bottom: 0 !important; }
  .modal .btn_style a, a.btn_style, button.btn_style {
    font-size: 14px; }
  #example4_modal.modal form p.btn_txt {
    margin-top: 100px; } }



@media (width: 414px) {
  body header .h_list {
    padding-left: 0;
    width: 75%;
    margin: 0 auto;
    margin-top: 45px; }
  body header .h_callback a.back_call:after,
  body header .h_callback a.back_call:before {
    width: 51%; }
  body header .h_callback {
    padding-left: 0; }
  body header .h_text p {
    font-size: 12px; }
  body header .title {
    font-size: 27px;
    text-align: center;
    line-height: 32px;
    padding-left: 0;
    width: 100%;
    margin: 0 auto;
    margin-top: 115px; }
  body header .subtitle {
    font-size: 18px; }
  body header .h_list p {
    font-size: 14px; }
  body header .h_list a.btn_style {
    margin-bottom: 240px;
    width: 100%;
    margin-left: 0; }
  #content1 .c_title {
    margin-top: 280px;
    font-size: 20px;
    line-height: 10px;
    padding-left: 0;
    p {
      line-height: 25px; } }
  #content1 .cnt1_blocks .block1 .col-md-9 {
    padding-left: 15px; }
  #content2 .c_title {
    font-size: 28px;
    text-align: left;
    padding-right: 35px; }
  hr.yellow {
    width: 30%;
    display: block;
    float: none !important;
    border-bottom: 5px solid #ffa900;
    text-align: center;
    margin-left: 2px !important;
    margin-right: auto; }
  #content2 .slide ul.s_list li,
  #content2 .slide ul.s_list2 li,
  #content2 .slide ul.s_list3 li {
    font-size: 13px; }
  #content2 .slide .s_title {
    font-size: 13px; }
  #content3 .cnt3_title {
    font-size: 25px;
    line-height: 30px;
    margin-top: 40px; }
  #content3 .cnt3_subtitle {
    font-size: 22px; }

  #content4 .c_title {
    margin-top: -25px;
    line-height: 30px;
    padding-left: 0px;
    font-size: 28px; }
  #content4 .cnt4_content ul.cnt4_list li p {
    font-size: 13px; }
  #content4 .cnt4_content ul.cnt4_list li {
    width: 100%; }
  #content4 .cnt4_content ul.cnt4_list a.btn_style {
    width: 100%;
    margin-left: -20px;
    margin-bottom: 10px;
    font-size: 15px; }
  #content4 .cnt4_content ul.cnt4_list a.btn_style:before,
  #content4 .cnt4_content ul.cnt4_list a.btn_style:after {
    width: 51%; }
  #content5 .cnt5_form_block .check_group p {
    font-family: Roboto Regular;
    font-size: 12px;
    display: block;
    float: left;
    width: 85%; }
  #content5 .cnt5_form_block p.btn_txt {
    font-family: Roboto Regular;
    font-size: 13px;
    text-align: center;
    color: #5d554e;
    margin-top: 80px; }
  #content6 .c_subtitle {
    font-size: 21px; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 {
    float: left;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0; }
  #content8 #answers2 .owl-next {
    margin-right: -40px; }
  #content8 #answers2 .owl-prev {
    margin-left: -40px; }
  #content5 .cnt5_title {
    font-size: 26px;
    line-height: 30px; }
  #content5 .cnt5_subtitle {
    font-size: 16px; }
  #content6 .col-md-6.col-md-offset-6 {
    padding-right: 0;
    padding-left: 0; }
  #content6 .cnt6_txt.col-md-10.col-md-offset-1 p {
    text-align: left; }
  #content6 .l_title {
    margin-top: 50px;
    border-bottom: none;
    padding-left: 0;
    padding-bottom: 5px; }
  #content6 .cnt6_blocks {
    border-bottom: none; }
  #content7 .cnt7_txt p {
    font-size: 14px; }
  #content7 .cnt7_txt ul.cnt7_list li {
    font-size: 14px; }
  #content8 .c_title {
    padding-left: 0; }
  #content10 .cnt10_left, #content10 .cnt10_right {
    margin-bottom: 0; }
  #content8 #answers2 {
    margin-top: 25px; }
  #content9 .cnt9_title {
    font-size: 26px;
    line-height: 30px; }
  #content9 .cnt9_subtitle {
    font-size: 20px; }
  #content9 .cnt9_form_block {
    margin-top: 20px; }
  #content9 .cnt9_form_block .check_group p {
    font-size: 12px;
    width: 85%; }
  #content9 .cnt9_form_block p.btn_txt {
    font-size: 14px;
    margin-top: 100px; }
  #content9 {
    padding-bottom: 20px; }
  #content7 .cnt7_title {
    padding-left: 0; }
  #content10 .c_title {
    padding-left: 0;
    line-height: 30px; }
  #content10 .l_title {
    font-size: 14px;
    padding-right: 0;
    text-align: center; }
  .l_descr {
    font-size: 13px; }
  #content10 a.btn_style {
    margin-top: 0;
    margin-left: 0;
    width: 100%; }
  a.btn_style:before, button.btn_style:before,
  a.btn_style:after, button.btn_style:after {
    width: 51% !important; }
  #content10 ul.cnt10_list li {
    margin: 15px 0;
    width: 100%; }
  #content10 .cnt10_content {
    margin: 0;
    width: 100%; }
  #content10 .cnt10_right {
    box-shadow: none;
    float: left;
    padding-top: 0;
    margin-bottom: 0; }
  #content11 #map {
    height: 470px; }
  #content11 .c_title {
    text-align: center;
    line-height: 30px; }
  #content11 i.icon {
    float: none;
    margin-right: auto;
    margin-top: auto;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px; }
  #content11 .map_descr {
    padding: 15px 0;
    padding-bottom: 30px; }
  #content11 p.adress {
    text-align: center; }
  #content11 p.number {
    text-align: center; }
  footer .f_text p {
    font-size: 12px; }
  footer .f_info p {
    font-size: 10px; }
  #content10 ul.cnt10_list i.icon {
    float: none;
    margin: 0 auto;
    margin-top: -20px;
    margin-bottom: 10px; }
  #content10 ul.cnt10_list {
    list-style-type: none;
    padding-left: 0; }
  #content11 hr.yellow {
    margin: 0 auto;
    float: none !important;
    margin-top: 0px;
    width: 100%; }
  #content11 a.btn_style {
    width: 60%; }
  #content1 .cnt1_blocks i.icon {
    margin-bottom: 10px;
    margin: 0 auto !important;
    margin-bottom: 5px !important; }
  #content1 .cnt1_blocks .cnt1_block {
    padding-right: 0; }
  #content1 .cnt1_blocks p.b_title {
    text-align: center; }
  .modal form .check_group p {
    font-family: Roboto Regular;
    font-size: 12px;
    float: left;
    width: 90%; }
  .modal form p.btn_txt {
    font-size: 12px;
    margin-top: 80px;
    padding-bottom: 0 !important; }
  .modal .btn_style a, a.btn_style, button.btn_style {
    font-size: 14px; }
  #example4_modal.modal form p.btn_txt {
    margin-top: 100px; } }
@media (width: 568px) {
  body header .h_list {
    width: 60%; }
  .c_title {
    font-size: 27px; }
  #content1 .content {
    margin-top: 0; }
  #content3 .cnt3_title {
    font-size: 30px;
    line-height: 35px; }
  #content3 .cnt3_subtitle {
    font-size: 20px; }
  #content4 .cnt4_img1 {
    top: -185px; }
  #content4 .c_title {
    width: 100%;
    font-size: 27px;
    line-height: 30px;
    margin-top: 125px; }
  #content5 .cnt5_form_block button.btn_style:before, #content5 .cnt5_form_block button.btn_style:after {
    width: 51%; }
  #content6 .c_title {
    margin-top: 160px; }
  #content6 .c_subtitle {
    font-size: 22px; }
  *:before, *:after {
    width: 51% !important; }
  #content6 .cnt6_blocks .cnt6_block {
    width: 50%;
    float: left;
    min-height: 180px; }
  #content7 .cnt7_title {
    font-size: 40px; }
  #content9 .cnt9_form_block p.btn_txt {
    margin-top: 100px; }
  #content10 .l_title {
    font-size: 13px; }
  #content10 .l_descr {
    font-size: 12px; }
  footer .f_text p {
    text-align: right;
    font-size: 12px;
    margin-bottom: 22px; }
  footer .f_info p {
    font-size: 10px; }
  #content9 .cnt9_form_block .check_group p {
    font-family: Roboto Regular;
    font-size: 14px;
    float: left;
    width: 90%; }
  #content5 .cnt5_form_block .check_group p {
    font-family: Roboto Regular;
    font-size: 14px;
    float: left;
    width: 90%; }

  #example4_modal .modal-body {
    padding-left: 30%;
    padding-right: 60px;
    padding-top: 40px; }
  #example4_modal .modal-dialog {
    background-position: 70% 0; }

  #spor_modal .modal-body, #example_modal .modal-body, #example2_modal .modal-body, #example3_modal .modal-body {
    padding-left: 42%;
    padding-top: 0; }
  #spor_modal .modal-dialog, #example_modal .modal-dialog, #example2_modal .modal-dialog, #example3_modal .modal-dialog {
    margin: 0; }
  .modal form p.btn_txt {
    font-family: Roboto Regular;
    font-size: 12px;
    text-align: center;
    color: #5d554e;
    margin-top: 100px; }
  #content9 .cnt9_form_block button.btn_style,
  #content5 .cnt5_form_block button.btn_style {
    width: 100%; }
  #back_call_modal p.btn_txt {
    padding-bottom: 0;
    margin-top: 77px; }
  #content1 .cnt1_blocks .cnt1_block {
    min-height: 250px; }
  .modal .check_group p {
    font-family: Roboto Regular;
    font-size: 11px !important;
    float: left;
    width: 85%; }
  #back_call_modal .modal-body {
    padding-left: 43%;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px; } }


@media (width: 667px) {
  #content1 .cnt1_blocks .cnt1_block {
    min-height: 250px; } }
